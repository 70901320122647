import { gql } from '@apollo/client';

export const getWarmTransferHistoryQuery = gql`
  query getWarmTransfersResults($filters: O8FiltersInput) {
    WarmTransfersResults(filters: $filters) {
      results {
        apiReferenceUuid
        createdOn
        status
        timestamp
        availability
        leadExternalIdentifier
        leadSource
        ani
        stateFromAd
        phone
        uuid
        country
        pendingWarmLeadsForState
        availableAgents
        agentsOnCall
        ongoingWarmLeadsForState
        ongoingWarmLeads
        availableAgentsForState
        agentsOnCallForState
        pendingWarmLeads
        callTransferStatus
        warmTransferSkill
      }
    }
  }
`;
