import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  CALL_TRANSFER_STATUS,
  FilterComparators,
  LiveContactEvent,
  RecommendedTask,
  WarmTransferEvent,
  WarmTransferRecord,
} from 'common.interfaces';
import { Observable, map, of } from 'rxjs';
import { WarmTransferApi } from './warm-transfer.api';
import { SseService } from '@origin8-web/core-ui/sse';
import { O8ConfigService } from '@origin8-web/core-ui/config';
import { Protec8Env } from '../../environments';
import { withTrailingSlash } from '@origin8-web/o8-utils/format';
import { DateTime } from 'luxon';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class WarmTransferService {
  private readonly warmTransferApi = inject(WarmTransferApi);
  private readonly sseService = inject(SseService);
  private readonly o8ConfigService = inject<O8ConfigService<Protec8Env>>(O8ConfigService);
  private readonly platformId = inject(PLATFORM_ID);

  getWarmTransferHistoryToday(): Observable<WarmTransferRecord[]> {
    return this.warmTransferApi
      .getWarmTransferHistory({
        and: [
          {
            key: 'createdOn',
            comparator: FilterComparators.GREATER,
            value: DateTime.now().startOf('day').toJSDate(),
          },
          {
            key: 'createdOn',
            comparator: FilterComparators.LOWER,
            value: DateTime.now().endOf('day').toJSDate(),
          },
        ],
      })
      .pipe(
        map((wts) => {
          return wts.filter((wt) =>
            [CALL_TRANSFER_STATUS.COMPLETED, CALL_TRANSFER_STATUS.FAILED, CALL_TRANSFER_STATUS.REJECTED].some(
              (s) => s === wt.callTransferStatus,
            ),
          );
        }),
      );
  }

  checkAvailabilityForWarmTransfer(task: RecommendedTask): Observable<boolean> {
    return this.warmTransferApi.checkAvailabilityForWarmTransfer(task.leadInfo);
  }

  getWarmTransferEvent(onSseStart?: () => void): Observable<WarmTransferEvent> {
    const url =
      withTrailingSlash(this.o8ConfigService.get('securedApiGateway') as string) + 'get-warm-transfer-sse-signed-url';
    return this.sseService
      .subscribeToSecureSse<WarmTransferEvent & { createdOn: string }>(url, {
        onSseStart,
        delay: 1000,
        count: 60,
      })
      .pipe(
        map((wte) => {
          return { ...wte, createdOn: DateTime.fromISO(wte.createdOn).toJSDate() };
        }),
      );
  }

  getLiveContactEvent(): Observable<LiveContactEvent[]> {
    if (isPlatformServer(this.platformId)) {
      return of([]);
    }

    const url =
      withTrailingSlash(this.o8ConfigService.get('securedApiGateway') as string) +
      'advocate/get-live-contact-status-signed-url';
    return this.sseService.subscribeToSecureSse<LiveContactEvent[]>(url);
  }
}
