import { Inject, Injectable } from '@angular/core';
import { GraphQlClientLoader, GRAPHQL_CLIENT_LOADER } from '@origin8-web/core-ui/graphql';
import { map, Observable } from 'rxjs';
import { O8RootQueryFilter, OpenLead, WarmTransferRecord } from 'common.interfaces';
import { getWarmTransferHistoryQuery } from './gql/warm-transfer.query';
import { checkAvailabilityForWtMutation } from './gql/warm-transfer.mutation';
import { fp } from '@origin8-web/o8-utils/fp';

@Injectable({
  providedIn: 'root',
})
export class WarmTransferApi {
  constructor(@Inject(GRAPHQL_CLIENT_LOADER) private graphqlClientLoader: GraphQlClientLoader) {}

  checkAvailabilityForWarmTransfer(lead: OpenLead): Observable<boolean> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .mutate<{ warmTransferRequest: { available: boolean } }>({
        mutation: checkAvailabilityForWtMutation,
        variables: {
          warmTransferPayload: {
            leadDetails: fp.pick(lead, 'stateFromAd', 'phone', 'externalIdentifier'),
          },
        },
      })
      .pipe(map((res) => res.data?.warmTransferRequest.available ?? false));
  }

  getWarmTransferHistory(filters: O8RootQueryFilter<WarmTransferRecord>): Observable<WarmTransferRecord[]> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ WarmTransfersResults: { results: WarmTransferRecord[] } }>({
        query: getWarmTransferHistoryQuery,
        fetchPolicy: 'no-cache',
        variables: { filters },
      })
      .pipe(
        map((res) => {
          return res.data.WarmTransfersResults.results;
        }),
      );
  }
}
